import * as React from 'react'

import { Link, graphql } from 'gatsby'
import kebabCase from 'lodash/kebabCase'

import Layout from '../components/core/Layout'

/* Add stats on total number of posts per tag and total number of posts/tags */
/* TODO: review how other tag pages are created */
/* TODO: could add a search filter for tags here as well */
const TagsPage = ({
  data: {
    // @ts-ignore
    allMdx: { group },
  },
}) => (
  <Layout>
    <div className="bg-gray-800 w-full py-5 text-white dark:text-white">
      <div className="flex flex-col align-items items-center mx-auto">
        <h3 className="text-5xl font-bold">Tags</h3>
      </div>
    </div>

    <div className="w-full">
      <div className="flex flex-col max-w-3xl mx-auto mt-12">
        <div className="mx-auto px-4 bg-primary">
          <p className="my-4">
            The following is a list of all the tags across the site - maybe this
            can help you find what you need!
          </p>
          <ul className="flex flex-wrap gap-x-2 gap-y-3">
            {group.map((tag: any) => (
              <li
                className="bg-blue-600 text-white px-2 py-1 rounded-md"
                key={tag.fieldValue}
              >
                <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                  {tag.fieldValue} ({tag.totalCount})
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </Layout>
)

export default TagsPage

export const pageQuery = graphql`
  query {
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
